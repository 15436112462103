<template>
  <div>
    <b-row v-if="permissions.includes('employee-inquiry')">
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col md="3">
              <b-form-group label-for="select_filter">
                <b-form-select
                  id="select_filter"
                  v-model="selected"
                  :options="options"
                />
                <!-- Selected: <strong>{{ selected }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="9">
              <b-form-group label-for="search">
                <b-form-input
                  id="search"
                  placeholder="Search..."
                  v-model="search"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mt-1"
                @click="getInquiry(1, search)"
              >
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right mb-1">
        <router-link
          to="/users/add"
          v-if="permissions.includes('employee-create')"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            pill
            class="px-3 ml-1"
          >
            Add Employee
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-card v-if="permissions.includes('employee-inquiry')">
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Organization -->
          <span
            v-else-if="props.column.field === 'organization'"
            class="text-nowrap"
          >
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.organization_name }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              <span v-if="props.row.status === 1">Active</span>
              <span v-else>Non-Active</span>
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/users/' + props.row.id }"
                  v-if="permissions.includes('employee-detail')"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteEmployees(props.row.id)"
                  v-if="permissions.includes('employee-delete')"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ meta.from }} to {{ meta.to }}
              </span>
              <span class="text-nowrap"
                >&nbsp;of {{ meta.total }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => getInquiry(value, this.search)"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormFile,
  BModal,
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormFile,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "NIP",
          field: "nip",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Contact",
          field: "phone",
        },
        {
          label: "Organization",
          field: "organization",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
          width: "100px",
        },
      ],
      selected: "nip",
      options: [
        { value: "nip", text: "NIP" },
        { value: "name", text: "Name" },
      ],
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      search: "",
      rows: [],
      searchTerm: "",
      errors: "",
      errMessage: "",
      file: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-danger",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getInquiry(1, "");
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry($page, $search) {
      this.$http
        .get(
          "employees?page=" +
            $page +
            ($search ? "&" + this.selected + "=" + $search : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          // console.log(res.data.data)
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteEmployees($id) {
      this.$http
        .post("employees/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Employee has been Deleted",
              variant: "danger",
            },
          });
          location.href = "/users";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>